import { inject } from '@angular/core';
import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { DialogService } from '@isaia/components/dialog';
import { catchError, throwError } from 'rxjs';
import { hasErrorPopup, HttpErrorPopupConfig } from './http-error.context';
import { HttpErrorException } from '@isaia/entity/http';
import { ERROR_MESSAGE } from '../error/error-code.formatter';
import { translate } from '@ngneat/transloco';
import { AuthService } from '../auth/auth.service';

const HTTP_ERROR_POPUP_CLASS = 'isa-popup--http-error';

function getErrorMessage(e: HttpErrorResponse, config: HttpErrorPopupConfig) {
  const showDebugUrl = config.debug !== false;
  const code = (e?.error as HttpErrorException)?.error?.code;
  const messageFromErrorPopup = typeof config.message === 'function' ? config.message(e) : config.message;
  const messageFormatted = messageFromErrorPopup || ERROR_MESSAGE[code!]?.(translate) || '';
  const messageDetail = (e.error?.error?.detail || '').trim();
  const message = messageFormatted || messageDetail;
  const debug = message ? '' : showDebugUrl ? e.message || 'Unknown error' : '';
  return [message, debug].filter((v) => !!v).join('\n\n');
}

const isUnauthorized = (e: HttpErrorResponse) => e.status === HttpStatusCode.Unauthorized;

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const errorPopup = hasErrorPopup(req);
  if (typeof errorPopup === 'boolean' && !errorPopup) {
    return next(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (isUnauthorized(e)) {
          authService.logout();
        }
        return throwError(() => e);
      }),
    );
  }

  const dialogService = inject(DialogService);
  return next(req).pipe(
    catchError((e: HttpErrorResponse) => {
      if (isUnauthorized(e)) {
        authService.logout();
        // we don't want to show any error message so we throwError here
        return throwError(() => e);
      }

      const exception = e.error as HttpErrorException;
      const config = errorPopup as HttpErrorPopupConfig;
      const title = config.title || exception?.error?.title || 'Http Error';
      const message = getErrorMessage(e, config);
      dialogService.openPopup({ title, message }, { panelClass: HTTP_ERROR_POPUP_CLASS, disableClose: false });
      return throwError(() => e);
    }),
  );
};
