import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { LANGUAGE_HTTP_ORIGIN } from './language-origin.token';
import { of } from 'rxjs';

@Injectable()
export class LanguageHttpLoader implements TranslocoLoader {
  private readonly http = inject(HttpClient, { optional: true });
  private readonly languageHttpOrigin = inject(LANGUAGE_HTTP_ORIGIN, { optional: true });

  public getTranslation<T = Translation>(langPath: string) {
    if (!this.http || !this.languageHttpOrigin) {
      console.warn(this.constructor.name + ' needs: HttpClient and LANGUAGE_HTTP_ORIGIN providers');
      return of({} as T);
    }
    return this.http.get<T>(`${this.languageHttpOrigin}/${langPath}.json`);
  }
}
