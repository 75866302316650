import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SpinnerComponent } from '@isaia/components/spinner';
import { ApplicationRepository } from './core/application';
import { AuthRepository } from './core/auth';
import { LocalStorageService } from '@isaia/local-storage';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterData, RouterResolverService } from './core/router';
import { mergeMap, Observable, of, shareReplay } from 'rxjs';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [RouterOutlet, SpinnerComponent, TranslocoDirective],
  selector: 'mtm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public readonly routerResolverService = inject(RouterResolverService);
  public readonly router = inject(Router);
  public readonly authRepository = inject(AuthRepository);
  public readonly localStorageService = inject(LocalStorageService);
  public readonly applicationRepository = inject(ApplicationRepository);
  private readonly activatedRoute$ = this.routerResolverService
    .observeActivatedRoute(inject(ActivatedRoute))
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private readonly activatedRouteData$ = this.activatedRoute$.pipe(
    mergeMap((route) => (route?.data || of({})) as Observable<RouterData | undefined>),
  );

  constructor() {
    this.routerResolverService.navigationEnd$.pipe(takeUntilDestroyed()).subscribe();
    this.activatedRouteData$.subscribe((data) => {
      this.applicationRepository.setVisibility(data?.visibility);
    });

    this.authRepository.onLogout(() => {
      this.localStorageService.clear();
    });
  }
}
