import { createEnvironment } from './environment.model';
import { ORIGIN_PROD_API, ORIGIN_PROD_APP } from './_origin';

export const environment = createEnvironment({
  production: true,
  name: 'production',
  encryptionStorage: 'aes',
  sentry: {
    sampleRate: 1.0,
  },
  origin: {
    app: ORIGIN_PROD_APP,
    api: ORIGIN_PROD_API,
  },
});
