import { makeEnvironmentProviders } from '@angular/core';
import { provideTransloco } from '@ngneat/transloco';
import { LanguageService } from './language.service';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { LanguageHttpLoader } from './language.loader';
import { LANGUAGE_HTTP_ORIGIN } from './language-origin.token';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

type LanguageOptions = Parameters<typeof provideTransloco>[0] & Parameters<typeof provideTranslocoPersistLang>[0] & { origin: string };

export function provideLanguage(options?: Partial<LanguageOptions>) {
  return makeEnvironmentProviders([
    provideTransloco({
      config: {
        availableLangs: ['en'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        ...options?.config,
        missingHandler: {
          useFallbackTranslation: true,
          logMissingKey: false,
          allowEmpty: false,
          ...options?.config?.missingHandler,
        },
      },
      loader: options?.loader || LanguageHttpLoader,
    }),
    provideTranslocoPersistLang({
      storageKey: options?.storageKey || 'language',
      storage: {
        useValue: localStorage,
        ...options?.storage,
      },
    }),
    provideTranslocoMessageformat(),
    { provide: LANGUAGE_HTTP_ORIGIN, useValue: options?.origin || '' },
    LanguageService,
    options?.loader ? [] : LanguageHttpLoader,
  ]);
}
